var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "company-what-is-mew" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "5" } },
                [
                  _c("app-block-title", {
                    staticClass: "pr-8",
                    attrs: { "no-page-title": "", data: _vm.titleData },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "7" } },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("@/assets/images/backgrounds/bg-spaceman.jpg"),
                      "max-width": "100%",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }